export default {
    statuses: {
        'New': { label: 'Novi', short: 'NR' },
        'Done': { label: 'Završen', short: 'ZR' },
        'Deleted': { label: 'Storniran', short: 'SR' },
    },
    type: {
        'Proform': { label: 'Predračun', short: 'PR', plural: 'Predračuni' },
        'Advance': { label: 'Avansni račun', short: 'AR', plural: 'Avansni računi' },
        'Normal': { label: 'Račun', short: 'R', plural: 'Računi' },
    }
}