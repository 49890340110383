<template>
  <div class="container">
    <div class="row border-bottom">
      <div class="col fs-4 p-3">
        {{ message }}
      </div>
    </div>
    <div
      class="row p-2 border-bottom"
      v-for="(item, index) in items"
      :key="index"
    >
      <div class="col-7">
        <p v-html="item['label']"></p>
      </div>
      <div class="col-5">
        <router-link
          :class="'btn btn-primary'"
          :to="item['url']"
          role="button"
          :target="item['target'] == 'blank' ? '_blank' : '_self'"
          >{{ item["btnText"] }}</router-link
        >
        <router-link
          v-if="item['url2']"
          :class="'btn btn-primary ms-3'"
          :to="item['url2']"
          role="button"
          :target="item['target2'] == 'blank' ? '_blank' : '_self'"
          >{{ item["btnText2"] }}</router-link
        >
        <router-link
          v-if="item['url3']"
          :class="'btn btn-primary ms-3'"
          :to="item['url3']"
          role="button"
          :target="item['target3'] == 'blank' ? '_blank' : '_self'"
          >{{ item["btnText3"] }}</router-link
        >
      </div>
    </div>
    <button
      v-for="(val, index) in Object.keys(actions)"
      :key="index"
      type="button"
      :class="`btn btn-${actions[val]['color'] ?? 'primary'} mt-2  me-2`"
      @click="actions[val]['action']"
      :disabled="actions[val]['disabled']"
    >
      <div
        :class="`d-inline-flex align-items-center bi ${
          actions[val]['icon'] ?? 'bi-pencil'
        }`"
      >
        &nbsp;{{ actions[val]["label"] }}
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: "list-dialog",
  components: {},
  props: ["message", "items", "actions"],
  data() {
    // return { disabled: [] };
  },
  methods: {
    // disableButton(idx) {
    //   this.disabled = [...this.disabled, idx];
    // },
  },
};
</script>

<style scoped>
</style>